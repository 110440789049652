import React from "react";
import LandingFooter from "../../components/LandingFooter/LandingFooter";
import LandingHeader from "../../components/LandingHeader";
import "./Download.css";

const Download = () => {
const handleDownload = () => {
    const password = prompt("Please enter the password for download:");
    fetch('/api/download_data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({password: password})
    })
    .then(response => {
        if (response.ok) {
            return response.text();
        } else {
            throw new Error('Incorrect password or other error.');
        }
    })
    .then(csvData => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'logging_data.csv';
        document.body.appendChild(a);
        a.click();
        a.remove();
    })
    .catch(error => alert(error.message));
};
    return (
        <div>
            <LandingHeader></LandingHeader>
            <button onClick={handleDownload} style={{ margin: '20px' }}>Download Data</button>
            <LandingFooter></LandingFooter>
        </div>
    );
};

export default Download;


